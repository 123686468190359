<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      v-model="show"
      max-width="500px"
      height:400px
      scrollable
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card width="500px" v-else>
        <v-card-title
          height="100px"
          dark
          color="#7253cf"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex">
            <img
              src="@/assets/DistrictCampaign/selectCampaignLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-6 pt-2 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="cursor: pointer; margin-top: -80px; margin-right: -15px"
            @click="toggleSelectCampaignModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-0 py-0" style="max-height: 230px">
          <v-simple-table class="px-0">
            <template v-slot:default>
              <tbody>
                <tr
                  class="tableRow"
                  v-for="(item, i) in campaignList"
                  :key="i"
                  @click="selectCampaign(item.id)"
                >
                  <td class="text-center py-4">
                    <div v-if="selectedCampaignData">
                      <v-icon
                        v-if="selectedCampaignID === item.id"
                        color="#2C1963"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                  <td class="text-left py-4" style="font-weight: 600">
                    <div style="font-size: 14px">
                      {{ item.start_date }} to {{ item.end_date }}
                    </div>
                    <div style="color: rgba(29, 29, 29, 0.6); font-size: 13px">
                      {{ item.campaign_id }}
                    </div>
                  </td>
                  <td class="text-center" style="font-weight: 700">
                    <v-chip :class="`${item.campaign_status.toUpperCase()}`"
                      >{{ item.campaign_status }} Campaign</v-chip
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="py-4"></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {
  API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
  // API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
  // API_ADMIN_GET_DISTRICT_DETAIL,
} from "@/constants/APIUrls";
import { PRINCIPAL } from "@/constants/ModuleKeys";
import { CAMPAIGN_ID, ID, TEACHER_ID } from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "SelectCampaignModal",
  data() {
    return {
      loading: true,
      campaignList: [],
      campaignObj: {},
      districtID: null,
      orgData: {},
      isPayoutDone: false,
    };
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        this.modalOpenCallback();
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  computed: {
    ...mapGetters({
      showGetter: "campaign/getSwitchCampaignModalShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleSelectCampaignModal({ show: value });
      },
    },
    dialogTitle() {
      return "Select a Campaign";
    },
    selectedCampaignData() {
      return this.selectedCampaign.campaignData;
    },
    selectedCampaignID() {
      return this.selectedCampaign.campaignData.id;
    },
    userType() {
      return authToken.decodedToken().user_team;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      toggleSelectCampaignModal: "campaign/toggleModal",
      selectCampaignAction: "campaign/updateSelectedCampaign",
    }),
    modalOpenCallback() {
      this.getCampaignData();
    },
    modalCloseCallback() {
      this.loading = true;
    },
    getCampaignData() {
      const _this = this;
      this.loading = true;
      const successHandler = (res) => {
        _this.isPayoutDone = res.data.is_payout_done;
        console.log("is_payout_done:", _this.isPayoutDone);
        _this.campaignList = res.data.campaign_list;
        // This gives out empty object when user is admin
        _this.orgData = {
          logo_url: res.data.organization_logo,
          name: res.data.organization_name,
          id: res.data.organization_id,
        };
        if (
          _.isEmpty(_this.$route.query) &&
          _.isEmpty(_this.selectedCampaign)
        ) {
          _this.selectCampaign(res.data.campaign_list[0].id);
        } else if (
          _.isEmpty(_this.selectedCampaign) &&
          _this.$route.query[CAMPAIGN_ID]
        ) {
          _this.selectCampaign(_this.$route.query[CAMPAIGN_ID]);
        }
        _this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.pageRefreshHandler();
      };
      let formData = {};
      let PANEL = this.$route.matched[0].path.substring(1);
      let PANEL_ID = PANEL + "_id";
      if (this.$route.query[PANEL])
        formData[PANEL_ID] = this.$route.query[PANEL];
      else if (
        this.$route.matched[0].path.substring(1) === "pe-teacher" ||
        this.$route.matched[0].path.substring(1) === PRINCIPAL
      )
        formData[TEACHER_ID] = this.$route.query["teacher"];
      return Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    selectCampaign(id) {
      const self = this;
      let campaign =
        this.campaignList[self.campaignList.findIndex((obj) => obj[ID] == id)];
      let organizationData = {};
      let campaignData = {};
      let USER_PANEL = this.$route.matched[0].path.substring(1);
      const {
        campaign_id,
        campaign_status,
        start_date,
        campaign_type,
        end_date,
        school_default_goal,
        student_default_goal,
        teacher_default_goal,
        no_of_campaign,
        sub_domain,
        // organization_id,
        // organization_logo,
        // organization_name,
      } = campaign;
      console.log(campaign, "campaign djfhshsd", this.userType);
      // if (campaign_type === 'district' && USER_PANEL === 'school') {
      //   let routeData = this.$router.resolve({name: ROUTER_URL.districtPanel.children.dashboard.name, query:{ [campaign_type]: district, [CAMPAIGN_ID]: id}});
      //   window.open(routeData.href, '_blank');
      //   return
      // } else if (campaign_type === 'school' && USER_PANEL === 'district') {
      //   let routeData = this.$router.resolve({name: ROUTER_URL.schoolPanel.children.dashboard.name, query:{ [campaign_type]: school, [CAMPAIGN_ID]: id}});
      //   window.open(routeData.href, '_blank');
      //   return;
      // }
      if (this.userType === "admin") {
        const { id } = campaign;
        campaignData = {
          id,
          campaign_id,
          campaign_status,
          start_date,
          end_date,
          school_default_goal,
          student_default_goal,
          teacher_default_goal,
          no_of_campaign,
          campaign_type,
          sub_domain,
        };
        // organizationData = { logo_url:organization_logo, name:organization_name,id: organization_id};
        organizationData = this.orgData;
        if (campaign_type === "district") {
          campaignData = {
            ...campaignData,
            district: this.$route.query[USER_PANEL],
          };
          this.selectCampaignAction({
            organizationData,
            campaignData,
            isPayoutDone: this.isPayoutDone,
          });
        } else {
          campaignData = {
            ...campaignData,
            school: this.$route.query[USER_PANEL],
          };
          this.selectCampaignAction({
            organizationData,
            campaignData,
            isPayoutDone: this.isPayoutDone,
          });
        }
      }
      // else if (this.userType === "sales representative") {
      //     console.log("in the pannel");
      //     const { id } = campaign;
      //     campaignData = {
      //       id,
      //       campaign_id,
      //       campaign_status,
      //       start_date,
      //       end_date,
      //       school_default_goal,
      //       student_default_goal,
      //       teacher_default_goal,
      //       no_of_campaign,
      //       campaign_type,
      //       sub_domain,
      //     };
      //     organizationData = {
      //       logo_url: organization_logo,
      //       name: organization_name,
      //       id: organization_id,
      //     };
      //     this.selectCampaignAction({
      //       organizationData,
      //       campaignData,
      //       isPayoutDone: this.isPayoutDone,
      //     });
      //   }
      else {
        const { id } = campaign;
        organizationData = this.orgData;
        campaignData =
          this.campaignList[this.campaignList.findIndex((obj) => obj.id == id)];
        if (USER_PANEL === "district") {
          this.selectCampaignAction({
            organizationData,
            campaignData,
            isPayoutDone: this.isPayoutDone,
          });
        } else {
          this.selectCampaignAction({
            organizationData,
            campaignData,
            isPayoutDone: this.isPayoutDone,
          });
        }
      }
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, [CAMPAIGN_ID]: id },
      });
      this.toggleSelectCampaignModal({ show: false });
      this.$emit("reload");
    },
    pageRefreshHandler() {
      if (
        this.currentRouteName ===
        ROUTER_URL.districtPanel.children.schoolManagement.name
      ) {
        console.log("hi i am in school campaign panel");
        this.$root.$refs.districtSchool.getSchoolManagementData();
      } else if (
        this.currentRouteName ===
          ROUTER_URL.districtPanel.children.teacherManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.schoolPanel.children.teacherManagement.name
      ) {
        this.$root.$refs.teacherManagemnet.getTeacherData();
      } else if (
        this.currentRouteName ===
          ROUTER_URL.schoolPanel.children.studentManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.districtPanel.children.studentManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.teacherPanel.children.studentManagement.name
      ) {
        this.$root.$refs.studentManagement.getStudentData();
      } else if (
        this.currentRouteName ===
          ROUTER_URL.schoolPanel.children.sponsorManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.districtPanel.children.sponsorManagement.name
      ) {
        this.$root.$refs.sponsorManagement.getSponsorData();
      } else if (
        this.currentRouteName ===
          ROUTER_URL.schoolPanel.children.donationManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.districtPanel.children.donationManagement.name
      ) {
        this.$root.$refs.donationManagement.getDonationData();
      } else if (
        this.currentRouteName ===
          ROUTER_URL.schoolPanel.children.teamManagement.name ||
        this.currentRouteName ===
          ROUTER_URL.districtPanel.children.teamManagement.name
      ) {
        this.$root.$refs.teamManagement.getTeamData();
      }else if(this.currentRouteName===ROUTER_URL.peteacherPanel.children.dashboard.name){
        this.$root.$refs.peteacherDashboard.getOverviewData()
      }else if(this.currentRouteName ===ROUTER_URL.schoolPanel.children.galleryManagement.name ||
      this.currentRouteName===ROUTER_URL.teacherPanel.children.galleryManagement.name){
        this.$root.$refs.galleryManagement.getGalleryAssets()
      }
    },
  },
  mounted() {
    this.getCampaignData();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
span.v-chip.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
}
span.v-chip.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
}
span.v-chip.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
}
span.v-chip.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
}
tr.tableRow {
  cursor: pointer;
}
</style>
